import { type AppType } from "next/dist/shared/lib/utils";
import "~/styles/globals.css";
import { Analytics } from "@vercel/analytics/react";
import { api } from "~/utils/api";
import {
  HighlightInit,
  ErrorBoundary as HighlightErrorBoundary,
} from "@highlight-run/next/client";
import { env } from "~/env.mjs";

const MyApp: AppType = ({ Component, pageProps }) => {
  return (
    <>
      <HighlightInit
        projectId={env.NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID}
        tracingOrigins
        networkRecording={{ enabled: true, recordHeadersAndBody: true }}
      />

      <HighlightErrorBoundary showDialog>
        <Component {...pageProps} />
      </HighlightErrorBoundary>
      <Analytics />
    </>
  );
};

export default api.withTRPC(MyApp);
